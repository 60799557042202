import React, { useState } from 'react'

const defaultTheme = 'dark'

export const ThemeContext = React.createContext({
  themeMode: defaultTheme,
  setThemeMode: () => {},
})

const ThemeProvider = props => {
  const [themeMode, setThemeMode] = useState(defaultTheme)
  const invertedTheme = themeMode === 'light' ? 'dark' : 'light'
  const value = { themeMode, invertedTheme, setThemeMode }

  return (
    <ThemeContext.Provider value={value}>
      {props.children}
    </ThemeContext.Provider>
  )
}

export default ({ element }) => <ThemeProvider>{element}</ThemeProvider>
